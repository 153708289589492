// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = [];

    reportTypes.push({
        value: "student-course",
        text: i18n.t("student_course"),
        permission: "studentcourse_import",
    });

    reportTypes.push({
        value: "student-course-delete",
        text: i18n.t("student_course_delete"),
        permission: "studentcourse_bulkdelete",
    });

    reportTypes.push({
        value: "student-semester-status-update",
        text: i18n.t("student_semester_status_update"),
        permission: "studentsemester_updatestudentsemesterstatus",
    });

    reportTypes.push({
        value: "sevis",
        text: i18n.t("upload_sevis_data"),
        permission: "registration_importsevis",
    });

    return reportTypes;

}


